html {
    font-family: sans-serif;
}
body {
    margin: 0;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden], template {
    display: none;
}
a {
    background-color: transparent;
}
a:active, a:hover {
    outline: 0 none;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b, strong {
    font-weight: bold;
}
dfn {
    font-style: italic;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
mark {
    background: #ff0 none repeat scroll 0 0;
    color: #000;
}
small {
    font-size: 80%;
}
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
img {
    border: 0 none;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 1em 40px;
}
hr {
    box-sizing: content-box;
    height: 0;
}
pre {
    overflow: auto;
}
code, kbd, pre, samp {
    font-family: monospace,monospace;
    font-size: 1em;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
button {
    overflow: visible;
}
button, select {
    text-transform: none;
}
button, html input[type="button"], input[type="reset"], input[type="submit"] {
    cursor: pointer;
}
button[disabled], html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0 none;
    padding: 0;
}
input {
    line-height: normal;
}
input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
input[type="search"] {
    box-sizing: content-box;
}
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0 none;
    padding: 0;
}
textarea {
    overflow: auto;
}
optgroup {
    font-weight: bold;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td, th {
    padding: 0;
}
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: local("Material Icons"), local("MaterialIcons-Regular"), url("../fonts/materialdesignicons.eot#iefix") format("embedded-opentype"), url("../fonts/materialdesignicons.woff") format("woff"), url("../fonts/materialdesignicons.svg") format("svg"), url("../fonts/materialdesignicons.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.eot") format("embedded-opentype"), url("../fonts/Roboto-Regular.otf") format("otf"), url("../fonts/Roboto-Regular.ttf") format("truetype");
}
html {
    height: 100%;
}
body {
    color: #24282d;
    font-family: "Roboto",sans-serif;
    font-size: 14px;
    height: 100%;
    line-height: 18px;
    margin: 0 auto;
}
.auth__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    width: 100%;
}
.auth__content {
    background: rgba(0, 0, 0, 0) url("../img/background_main.jpg") no-repeat scroll center center / cover ;
    box-sizing: border-box;
    flex: 1 0 auto;
    height: 80%;
    width: 100%;
}
.auth__background {
    left: 50%;
    position: absolute;
    top: 50%;
}
.auth__logo {
    margin-top: 20px;
    min-height: 27%;
    width: 100%;
}
.auth__icon {
    display: block;
    font-size: 0;
    height: 77px;
    margin: 0 auto;
    width: 295px;
}
.auth__row {
    width: 100%;
}
.auth__row--support {
    background-color: #f2f2f2;
    display: flex;
    flex: 0 0 auto;
    height: 44px;
    line-height: 44px;
}
.auth__userBlock {
    background-color: rgba(253, 253, 253, 0.2);
    box-sizing: border-box;
    height: 286px;
    margin: 0 auto;
    padding-top: 30px;
    width: 330px;
}
.auth__title {
    font-size: 14px;
    line-height: 32px;
    margin-bottom: 28px;
    text-align: center;
    text-transform: uppercase;
}
.auth__input {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    color: #707277;
    font-weight: 500;
    height: 29px;
    margin: 0 auto 11px;
    width: 215px;
}
.auth__input:last-of-type {
    margin-bottom: 0;
}
.auth__help {
    display: block;
    text-align: center;
    width: 100%;
}
.auth__btn {
    background-color: #ed5a5b;
    border: 0 none !important;
    color: #ffffff !important;
    display: block;
    font-size: 14px;
    font-weight: 500;
    height: 34px;
    line-height: 32px;
    margin: 0 auto;
    width: 215px;
}
.auth__contact {
    align-items: center;
    height: 24px;
    padding-right: 9px;
    text-align: right;
    width: 50%;
    font-weight: 500;
}
.auth__contact--email {
    padding-left: 9px;
    padding-right: 0;
    text-align: left;
}
.auth__city {
    display: block;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.auth__link {
    text-align: center;
}
.auth__link:focus {
    outline: medium none;
}

#app-layout{
    top:0px; !important;
}
